import { useMemo } from "react";

import useResizeWindow from "hooks/use-resize-window";

import { SIZE } from "constants/styles";

const useWindowSizes = (): WindowSizes => {
  const [windowSize] = useResizeWindow();

  return useMemo(() => {
    const isMobileWidth = SIZE.getIsMobileWidth(windowSize);

    return {
      isMobileWidth,
      isMobileLargeWidth: SIZE.getIsMobileLargeWidth(windowSize),
      isMobileMediumWidth: SIZE.getIsMobileMediumWidth(windowSize),
      isMobileSmallWidth: SIZE.getIsMobileSmallWidth(windowSize),
      isTabletSmallWidth: SIZE.getIsTabletSmallWidth(windowSize),
      isTabletWidth: SIZE.getIsTabletWidth(windowSize),
      isTabletLargeWidth: SIZE.getIsTabletLargeWidth(windowSize),
      isDesktopSmallWidth: SIZE.getIsDesktopSmallWidth(windowSize),
      isDesktopMediumWidth: SIZE.getIsDesktopMediumWidth(windowSize),
      isDesktopLargeWidth: SIZE.getIsDesktopLargeWidth(windowSize),
      isDesktopWidth: SIZE.getIsDesktopWidth(windowSize),
      size: windowSize,
      headerHeight: isMobileWidth ? 72 : 96,
    };
  }, [windowSize]);
};

export default useWindowSizes;
