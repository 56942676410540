import { PropsWithChildren } from "react";
import joinClassNames from "classnames";

import Folder from "icons/empty/folder.svg?react";
import Search from "icons/empty/search.svg?react";
import Transaction from "icons/empty/transaction.svg?react";

import { Type } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  title?: string;
  subtitle?: string;
  type?: Type;
  className?: string;
  classNames?: Partial<{ title: string; description: string; icon: string }>;
}

const EmptyScreen = ({
  title = "Nothing found",
  subtitle = "For some reason we have nothing that should be in this section",
  type = "folder",
  children,
  className,
  classNames = {},
}: Props) => {
  let Icon = Folder;
  if (type === "search") {
    Icon = Search;
  }
  if (type === "transaction") {
    Icon = Transaction;
  }

  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      <Icon className={classNames.icon} />
      <div>
        {title && (
          <h2 className={joinClassNames(classes.title, classNames.title)}>
            {title}
          </h2>
        )}
        {subtitle && (
          <p
            className={joinClassNames(
              classes.description,
              classNames.description,
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default EmptyScreen;
